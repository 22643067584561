import {NgModule} from '@angular/core';
import {ConfirmationDialogModule} from "src/app/shared/components/confirmationDialog/confirmationDialog.module";
import {TableWithSelectionModule} from "src/app/shared/components/table-with-selection/table-with-selection.module";
import {LoadingModule} from "src/app/shared/components/loading-pages/loading.module";
import {ButtonsModule} from "src/app/shared/components/buttons/buttons.module";
import {ListaCadastrosComBuscaModule} from "src/app/shared/components/lista-cadastros-com-busca/lista-cadastros-com-busca.module";
import {ListaPermissoesModule} from "src/app/shared/components/listaPermissoes/listaPermissoes.module";
import {SearchInputModule} from "src/app/shared/components/search-input/search-input.module";
import {TitleButtonModule} from "src/app/shared/components/title-button/title-button.module";
import {UploadFilesComponent} from "src/app/shared/components/upload-files/upload-files.component";
import {AddPermissaoAcessoDialogModule} from "src/app/shared/components/addPermissaoAcessoDialog/addPermissaoAcessoDialog.module";
import {AddBilheteModule} from "src/app/shared/components/add-bilhete/add-bilhete.module";
import {BlockWithTitleModule} from "src/app/shared/components/block-with-title/block-with-title.module";

const MODULES = [
    AddPermissaoAcessoDialogModule,
    ButtonsModule,
    ConfirmationDialogModule,
    ListaCadastrosComBuscaModule,
    ListaPermissoesModule,
    LoadingModule,
    SearchInputModule,
    TableWithSelectionModule,
    TitleButtonModule,
    UploadFilesComponent,
    AddBilheteModule,
    BlockWithTitleModule,
];

@NgModule({
    imports: [...MODULES],
    exports: [...MODULES]
})
export class ComponentsModule {
}
