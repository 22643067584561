<div class="flex flex-col">
    <div class="flex justify-center mb-4">
        <h2 class="primary-text flex justify-center items-center">
            <mat-icon *ngIf="alert" class="warn-color mr-5px">warning</mat-icon>
            Confirmação
        </h2>
    </div>
    <div class="mt-5 mb-10 text-16 text-center whitespace-break-spaces w-full"
         [innerHTML]="confirmMessage">
    </div>

    <div class="flex justify-end gap-3">
        <button mat-stroked-button color="warn" (click)="dialogRef.close(false)" *ngIf="showCancelar">
            <span class="flex justify-center items-center">
                <mat-icon>close</mat-icon>
                {{ ok ? 'Cancelar' : 'Não' }}
            </span>
        </button>
        <button mat-flat-button color="primary" (click)="dialogRef.close(true)">
            <span class="flex justify-center items-center">
                <mat-icon>done</mat-icon>
                {{ ok ? 'OK' : 'Sim' }}
            </span>
        </button>
    </div>
</div>
