<div class="flex flex-row w-full items-stretch border-1 rounded" [ngClass]="'border-' + config.type">

    <div class="px-5 big-icon flex flex-row justify-center items-center content-center border-l-8" [ngClass]="'border-' + config.type">
        <mat-icon *ngIf="config.type === 'error'" class="text-error">error</mat-icon>
        <mat-icon *ngIf="config.type === 'warn'" class="text-warn">warning</mat-icon>
        <mat-icon *ngIf="config.type === 'success'" class="text-success">check_circle</mat-icon>
        <mat-icon *ngIf="config.type === 'info'" class="text-info">info</mat-icon>
    </div>

    <div class="flex flex-row flex-wrap justify-center items-center content-center p-1 w-10/12">
        <h4 *ngIf="config.title" [innerHTML]="config.title | safeHtml" class="w-full m-0 mb-1 leading-5 text-16 text-primary"></h4>
        <h5 *ngIf="config.message" [innerHTML]="config.message | safeHtml" class="w-full m-0 text-slate-500 text-14"></h5>
    </div>

</div>
