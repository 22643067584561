import {Injectable, TemplateRef} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {ConfirmationDialog} from 'src/app/shared/components/confirmationDialog/confirmationDialog.component';
import {Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {Abstract} from 'src/app/shared/models/abstract';
import {firstValueFrom, fromEvent, map, startWith, throttleTime} from 'rxjs';
import {JsonViewerComponent} from 'src/app/shared/components/json-viewer/json-viewer.component';
import {toSignal} from '@angular/core/rxjs-interop';
import {MySnackBarComponent} from 'src/app/shared/components/MySnackBar/mySnackBar.component';

@Injectable({
    providedIn: 'root',
})
export class Utils {
    public estados: string[] = [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
    ];

    public codigosBancos: {key: string; title: string}[] = [
        {key: '001', title: 'BANCO DO BRASIL S.A (BB)'},
        {key: '033', title: 'BANCO SANTANDER BRASIL S.A'},
        {key: '077', title: 'BANCO INTER S.A'},
        {key: '212', title: 'BANCO ORIGINAL S.A'},
        {key: '237', title: 'BRADESCO S.A'},
        {key: '260', title: 'NU PAGAMENTOS S.A (NUBANK)'},
        {key: '341', title: 'ITAÚ UNIBANCO S.A'},
        {key: '748', title: 'SICREDI S.A'},
    ];

    public tiposDocMoradorLocal: {key: string; title: string}[] = [
        {key: 'CPF', title: 'CPF'},
        {key: 'CNPJ', title: 'CNPJ'},
        {key: 'RG', title: 'RG'},
        {key: 'PASS', title: 'PASSAPORTE'},
        {key: 'OUTROS', title: 'Outros'},
    ];

    constructor(private snackBar?: MatSnackBar, private dialog?: MatDialog, private router?: Router) {}

    public round(num: number, decimalPlaces = 0) {
        num = Math.round(+(num + "e" + decimalPlaces));
        return Number(num + "e" + -decimalPlaces);
    }

    public randomString(length: number): string {
        let result: string = '';
        let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength: number = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static normalize(str: string) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    isMobile$ = fromEvent(window, 'resize').pipe(
        throttleTime(100, undefined, {trailing: true, leading: true}),
        startWith(null),
        map(() => window.innerWidth < 600)
    );
    isMobile = toSignal(this.isMobile$, {initialValue: window.innerWidth < 600});

    isMD$ = fromEvent(window, 'resize').pipe(
        throttleTime(100, undefined, {trailing: true, leading: true}),
        startWith(null),
        map(() => window.innerWidth < 960)
    );
    isMD = toSignal(this.isMD$, {initialValue: window.innerWidth < 960});

    openSnackBar(message: string, type?: 'error' | 'success' | 'info', duration?: number) {
        const configs: {[key: string]: MatSnackBarConfig} = {
            error: {
                panelClass: ['snackBarError'],
                duration: 10000,
            },
            success: {
                panelClass: ['snackBarSuccess'],
                duration: 4000,
            },
            info: {
                panelClass: ['snackBarInfo'],
                duration: 6000,
            },
        };

        // Classes "snackBarError", "snackBarSuccess" e "snackBarInfo" estão no scss/styles.scss
        if (message) {
            let config: MatSnackBarConfig = configs[type];
            if (config && duration) {
                config.duration = duration;
            }
            this.snackBar.open(
                message,
                'X',
                config || {
                    duration: 3000,
                }
            );
        }
    }

    historyBack() {
        history.back();
    }

    public openDialogInfo(content: string | object) {
        return this.dialog.open(JsonViewerComponent, {
            maxWidth: '90vw',
            maxHeight: '90vh',
            autoFocus: false,
            data: content,
        });
    }

    public openCustomSnack(config: SnackMessageConfig) {
        this.snackBar.openFromComponent(MySnackBarComponent, {
            duration: config.duration || (config.type === messageTypes.error || config.type === messageTypes.warn ? 6000 : 2500),
            data: config,
            panelClass: ['custom-snackbar-container'],
            verticalPosition: 'bottom',
        });
    }

    async openConfirmationDialog(quest: string, config?: ConfigConfirmationDialog): Promise<boolean> {
        config = new ConfigConfirmationDialog().assign(config || {});
        let dialogRef = this.dialog.open(ConfirmationDialog, {
            maxHeight: '90vh',
            disableClose: false,
        });
        dialogRef.componentInstance.confirmMessage = quest;
        if (config) {
            if (config.ok) {
                dialogRef.componentInstance.ok = config.ok;
            }
            if (config.alert) {
                dialogRef.componentInstance.alert = config.alert;
            }
            dialogRef.componentInstance.showCancelar = !!config.showCancelar;
        }
        return firstValueFrom(dialogRef.afterClosed()).then((result) => Promise.resolve(result || null));
    }

    async openWarningDialog(quest: string, config?: ConfigWarningDialog): Promise<boolean> {
        config = new ConfigWarningDialog().assign(config || {});
        let dialogRef = this.dialog.open(ConfirmationDialog, {
            maxHeight: '90vh',
            disableClose: false,
        });
        dialogRef.componentInstance.confirmMessage = quest;
        if (config) {
            dialogRef.componentInstance.showCancelar = !!config.showCancelar;
        }
        return firstValueFrom(dialogRef.afterClosed()).then((result) => Promise.resolve(result || null));
    }

    async asyncDialog<Conf>(dialog: MatDialog, component: ComponentType<any> | TemplateRef<any>, config: MatDialogConfig<Conf>) {
        let dialogRef = dialog.open(component, config);
        return firstValueFrom(dialogRef.afterClosed()).then((result) => Promise.resolve(result || null));
    }

    async openNewPage(route: string, queryParams?: any) {
        let url: string = route;
        if (!route.includes('http://') && !route.includes('https://')) {
            url = this.router.serializeUrl(this.router.createUrlTree([route], {queryParams}));
        }
        url = url.replace('%23', '#');
        window.open(url, '_blank');
    }

    async findUserErrors(forms: FormGroup | FormGroup[]): Promise<{errosUsuario: string; firstError: string}> {
        let invalidControls: string[] = [];
        if (!(forms instanceof Array)) {
            forms = [forms];
        }

        for (let form of forms) {
            for (const name in form.controls) {
                if (form.controls[name].errors) {
                    invalidControls.push(name);
                }
            }
        }

        let firstError = invalidControls[0];

        let invalidControls2: string[] = [];

        for (let ctrlName of invalidControls) {
            let elementLabel = document.getElementById('label-' + ctrlName);
            invalidControls2.push(elementLabel?.innerText || ctrlName.charAt(0).toUpperCase() + ctrlName.slice(1));
        }

        return {
            errosUsuario: 'Informação inválida nos campos: ' + invalidControls2.join(', '),
            firstError,
        };
    }

    downloadPDF(pdf: any, nome: string) {
        const url = window.URL.createObjectURL(new Blob([new Uint8Array(pdf.data).buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nome);
        document.body.appendChild(link);
        link.click();
    }

    public getTextColorByHex(hex: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let rgb = result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
        if (!result) return 'black';

        let yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
        return yiq >= 128 ? 'black' : 'white';
    }

    // A função de round nativa do javascript não arrendonda corretamente em alguns casos
    // Esta versão é mais precisa
    // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    static round(num: number, decimalPlaces = 0) {
        num = Math.round(+(num + 'e' + decimalPlaces));
        return Number(num + 'e' + -decimalPlaces);
    }

    public mascararInfos(info: string): string {
        if (!info) return '';

        const length = info.length;

        if (length <= 3) {
            // If the length is 3 or less, return the string masked with '*'
            return '*'.repeat(length);
        }

        const primeirosCaracteres = info.substring(0, 3);
        const censoredPart = '*'.repeat(length - 3);

        return primeirosCaracteres + censoredPart;
    }
}

export class ConfigConfirmationDialog extends Abstract {
    ok?: boolean = false;
    alert?: boolean = true;
    showCancelar?: boolean = true;
}

export class ConfigWarningDialog extends Abstract {
    ok?: boolean = true;
    alert?: boolean = true;
    showCancelar?: boolean = false;
}

export interface SnackMessageConfig {
    type: messageTypes;
    title?: string;
    message?: string;
    duration?: number;
}

export enum messageTypes {
    error = 'error',
    warn = 'warn',
    info = 'info',
    success = 'success',
}
