import {BooleanInput} from '@angular/cdk/coercion';
import {NgClass, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, booleanAttribute, signal} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {Utils} from 'src/app/shared/utils/utils';
import * as WebpConverter from 'webp-converter-browser';

@Component({
    selector: 'upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatSnackBarModule, NgIf, NgClass, MatIconModule],
})
export class UploadFilesComponent {
    @Output() getFiles: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Input({ transform: booleanAttribute }) multiple: BooleanInput;
    @Input() accept: string;
    @Input() maxSize: number;
    @Input() maxImageSize: { width?: number; height?: number } | null = { width: 2000, height: 2000 };
    @Input() label: string;
    @Input({ transform: booleanAttribute }) dropFiles: BooleanInput;
    @Input() preview: string;
    @Input() alt: string;
    @Input() objectFit: 'cover' | 'contain' | 'fill';
    @Input() mimeTypeWebp: boolean = true;

    public dragover = signal(false);

    constructor(private utils: Utils) { }

    openFiles(element: HTMLInputElement) {
        element.value = null;
        element.click();
    }

    blobToBase64(file: Blob): Promise<string> {
        return new Promise((next, err) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event: ProgressEvent) => next(event.target['result']);
            reader.onerror = (ev) => {
                console.error(ev)
                err(`Não foi possível carregar o arquivo: ${ev}`)
            };
        });
    }

    async loadFile(files: File[]) {
        const outFiles: string[] = [];
        try {
            if (files) {
                for (let file of files) {
                    let fileResult: Blob = file;

                    if (this.maxImageSize && file.type.startsWith('image/') && !file.type.includes('svg')) {

                        // Criamos um elemento de imagem e carregamos ela para
                        // conseguir o tamanho dela em pixeis
                        const img = new Image();
                        img.src = await this.blobToBase64(fileResult);
                        await new Promise<void>((next, err) => {
                            img.onload = () => next();
                            img.onerror = (ev) => {
                                console.error(ev)
                                err(`Não foi possível carregar a imagem: ${ev}`)
                            };
                        });
                        let { width, height } = img;

                        // Calculamos o tamanho final da imagem a partir do ratio
                        // das larguras e alturas da imagem em relação ao tamanho
                        // que queremos atingir

                        if (this.maxImageSize?.width && width > this.maxImageSize.width) {
                            let ratio = this.maxImageSize.width / width;
                            width = this.maxImageSize.width;
                            height = Math.floor(height * ratio);
                        }
                        if (this.maxImageSize?.height && height > this.maxImageSize.height) {
                            let ratio = this.maxImageSize.height / height;
                            height = this.maxImageSize.height;
                            width = Math.floor(width * ratio);
                        }

                        if (this.mimeTypeWebp) {
                            fileResult = await WebpConverter.blobToWebP(fileResult, {
                                quality: 0.9,
                                width,
                                height,
                            });
                        } else {
                            fileResult = await this.convertToImageWithQuality(fileResult, {
                                quality: 0.9,
                                width,
                                height,
                                originalType: file.type,
                            });
                        }
                    }
                    if (this.maxSize && fileResult.size > this.maxSize) {
                        throw new Error(`Arquivo ${file.name} muito grande, deve ter menos de ${this.maxSize / 1000}KB`);
                    }
                    outFiles.push(await this.blobToBase64(fileResult));
                }
                this.getFiles.emit(outFiles);
            }
        } catch (e: any) {
            this.utils.openSnackBar(e.message, 'error');
        }
    }

    async convertToImageWithQuality(image: Blob, options: { quality: number; width: number; height: number; originalType: string }): Promise<Blob> {
        const img = new Image();
        img.src = await this.blobToBase64(image);
        return new Promise((resolve, reject) => {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = options.width;
                canvas.height = options.height;
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error('Erro ao obter contexto do canvas.'));
                    return;
                }
                ctx.drawImage(img, 0, 0, options.width, options.height);
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Erro ao converter a imagem para Blob.'));
                    }
                }, options.originalType, options.quality);
            };
            img.onerror = (error) => {
                reject(new Error('Erro ao carregar a imagem: ' + error));
            };
        });
    }

    dropFile(ev: DragEvent) {
        ev.preventDefault();
        let files: File[];
        if (ev.dataTransfer.items) {
            // If dropped items aren't files, reject them
            files = [...(ev.dataTransfer.items as any as Iterable<DataTransferItem>)]
                .filter((item) => item.kind === 'file')
                .map((item) => item.getAsFile());
        } else {
            // Use DataTransfer interface to access the file(s)
            files = [...(ev.dataTransfer.files as any as Iterable<File>)];
        }
        if (this.accept) {
            const matchStrings = this.accept.split(',');
            const mimeRegexs: RegExp[] = [];
            const extensionRegexs: RegExp[] = [];
            matchStrings.forEach((matchString) => {
                if (matchString.trim().startsWith('.')) {
                    extensionRegexs.push(new RegExp(matchString.replace(/[.+?^${}()|[\]\\]/g, '\\$&').trim() + '$'));
                } else {
                    mimeRegexs.push(
                        new RegExp(
                            matchString
                                .replace(/[.+?^${}()|[\]\\]/g, '\\$&')
                                .replace(/\*/g, '(.*?)')
                                .trim()
                        )
                    );
                }
            });
            const invalid = files.filter(
                (f) => !mimeRegexs.some((accRegex) => accRegex.test(f.type)) && !extensionRegexs.some((accRegex) => accRegex.test(f.name))
            );

            if (invalid.length) {
                this.utils.openSnackBar(`Arquivo${invalid.length > 1 ? 's' : ''} com formato invalido: ${invalid.map((f) => f.name).join(', ')}`, 'error');
                return;
            }
        }
        if (files.length) {
            this.loadFile(files);
        }
    }
}
